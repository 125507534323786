<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>外来课程审核管理</h2>

        <div class="box-bd">
          <el-button type="warning" @click="dialogLog.state = true"
            >查看日志</el-button
          >
          <!-- 头 -->
          <!-- <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加服务项目</el-button>
            </el-col>
          </el-row> -->
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="course_name" label="外来课程名称">
            </el-table-column>
            <el-table-column prop="file_pdf" label="pdf课件">
              <template slot-scope="scope">
                <a target="_blank" :href="scope.row.file_pdf" class="file-img">
                  <img width="50" src="../../../assets/pdf.png" />
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="file_pdf" label="ppt课件">
              <template slot-scope="scope">
                <a target="_blank" :href="scope.row.file_ppt" class="file-img">
                  <img width="50" src="../../../assets/ppt.png" />
                </a>
              </template>
            </el-table-column>
            <el-table-column prop="status" width="500" label="状态">
              <template slot-scope="scope">
                <!-- <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag> -->
                <el-tag v-if="scope.row.status === 6">已取消</el-tag>
                <el-steps
                  v-else
                  :space="150"
                  :active="scope.row.status - 1"
                  finish-status="success"
                >
                  <el-step title="审核中"></el-step>
                  <el-step title="试讲中"></el-step>
                  <el-step title="评估中"></el-step>
                  <el-step title="签署协议中"></el-step>
                  <el-step title="已完成"></el-step>
                </el-steps>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.oprate === 1"
                  size="mini"
                  type="warning"
                  icon="el-icon-edit-outline"
                  @click="handleEdit(scope.row)"
                  >执行</el-button
                >
                <el-button
                  v-if="scope.row.oprate === 1"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >取消</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getCoursesApList,
  editCourse,
  cancelCourse,
} from '@/api/edu/coursesapproval.js'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      btnLoading: false,
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        page_num: 7,
      },

      formRules: {
        name: {
          required: true,
          message: '请输入服务项目名称',
          trigger: 'blur',
        },
        age_min: {
          required: true,
          message: '请输入适合最小年龄',
          trigger: 'blur',
        },
        // age_max: {
        //   required: true,
        //   message: '请输入适合最大年龄',
        //   trigger: 'blur',
        // },
        type_id: {
          required: true,
          message: '请选择服务类型',
          trigger: 'change',
        },
        address_id: {
          required: true,
          message: '请选择服务地址',
          trigger: 'change',
        },
        hour: {
          required: true,
          message: '请输入课程时长',
          trigger: 'blur',
        },
      },

      addOrEdit: 1,
      formLabelWidth: '120px',
      guideListData: [],
      optionsTask: [],
      optionProps: {
        value: 'id',
        expandTrigger: 'hover',
        label: 'cname',
        children: 'sub',
      },
      checkedGuide: [],
      form: {
        name: '',
        photo_id: [],
        hour: '',
        address_id: [],
        tool: [],
        age_max: '',
        age_min: '',
        reward: '',

        teacher: [],
        type_id: '',
        content: {
          teach_goal: '',
          teach_important_point: '',
          teach_difficulty: '',
          teach_method: '',
        },
      },

      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.page_num = val
      this.getList()
    },

    async getList() {
      this.tableLoading = true
      const { data: res } = await getCoursesApList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },

    // 编辑打开
    async handleEdit(row) {
      this.$confirm('确认将课程状态推进到下一步？')
        .then(async (_) => {
          const { data: res } = await editCourse({
            course_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('操作成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },

    // 删除
    remove(row) {
      this.$confirm('确认取消该课程？')
        .then(async (_) => {
          const { data: res } = await cancelCourse({
            course_id: row.id,
          })
          if (res.code === 1) {
            this.$message.success('取消成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
.inner-table {
  font-size: 14px !important;
  font-weight: 400;
  margin-top: 10px;
  border-radius: 3px;
}
.inner-table >>> .el-table__cell {
  padding: 3px 0;
}

.task-table >>> .el-step__title {
  font-size: 14px;
}
.task-table >>> .el-step__title.is-process {
  font-weight: 400;
}
</style>
