import { post } from '@/utils/request'

//获取目录
export function getCoursesApList(data) {
    return post('/edu/external_course_check/courseList', data)
}



//操作
export function editCourse(data) {
    return post('/edu/external_course_check/editCourse', data)
}
//取消
export function cancelCourse(data) {
    return post('/edu/external_course_check/cancel', data)
}


 